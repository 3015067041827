import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import ContactView from '../views/ContactView.vue';
import BeliefsView from '../views/BeliefsView.vue';
import BeginningsView from '../views/BeginningsView.vue';
import CapabilitiesView from '../views/CapabilitiesView.vue';
import BenefitsView from '../views/BenefitsView.vue';
import LoginView from '../views/LoginView.vue';

const DEFAULT_TITLE = 'wereckon.com';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      title: 'Contact Reckon',
    },
  },
  {
    path: '/beliefs',
    name: 'beliefs',
    component: BeliefsView,
    meta: {
      title: 'Core Beliefs',
    },
  },
  {
    path: '/beginnings',
    name: 'beginnings',
    component: BeginningsView,
    meta: {
      title: 'How It Started',
    },
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutView,
    meta: {
      title: 'Meet The Team',
    },
  },
  {
    path: '/capabilities',
    name: 'capabilities',
    component: CapabilitiesView,
    meta: {
      title: 'What We Do',
    },
  },
  {
    path: '/benefits',
    name: 'benefits',
    component: BenefitsView,
    meta: {
      title: 'What We Do',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Login Test',
    },
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Check if there is a saved position (e.g., using browser back/forward)
    if (savedPosition) {
      return savedPosition;
    }

    // Scroll to the top of the page
    return { x: 0, y: 0 };
  },
});

// Not sure if this is where this belongs..
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
