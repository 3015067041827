<template>
  <div id="about-us">
    <AppHeader>Who We Are!</AppHeader>
    <div class="content">
      <!-- <div class="ml-6 my-5 text-h3 text-md-h2">Calling all Super Heroes!</div> -->
      <div class="mx-6 mx-md-15 text-h5">
        <div>
          <div class="text-section">
            <p>
              <!-- 🌟  -->
              Are you ready to unlock your true potential and join an extraordinary league of super
              heroes?
              <!-- 🌟 -->
            </p>
            <p class="mt-10">
              Are you tired of watching from the sidelines as the world faces its greatest
              challenges? Do you believe in justice, compassion, and making a difference? Well, it's
              time to step into the spotlight and answer the call to become a true superhero!
            </p>
          </div>
          <div style="margin-top: 50px" class="caps">
            <div>
              <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">
                <!-- 🦸‍♀️🦸‍♂️  -->
                Who Are We?
              </div>
              We are the Reckon Crew, an elite team of superheroes dedicated to protecting our world
              and making it a safer, more just place for all. Our mission is to stand up to
              injustice, combat evil, and inspire hope in the hearts of those who need it most.
            </div>
            <div>
              <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">
                <!-- 🌎  -->
                The World Needs You!
              </div>
              Crime, corruption, and chaos threaten to overwhelm our world, but we believe in the
              power of ordinary people to achieve the extraordinary. We are looking for individuals
              with extraordinary abilities, unwavering dedication, and a burning desire to make a
              difference.
            </div>
            <div>
              <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">
                <!-- 💪  -->
                What We're Seeking
              </div>
              <ul>
                <li>
                  <strong>Superhuman Abilities:</strong> Whether you have the strength of a
                  thousand, can fly through the skies, or possess any other incredible power, we
                  want to hear from you.
                </li>
                <li>
                  <strong>Unshakeable Ethics:</strong> Our heroes are known for their unwavering
                  commitment to doing what's right. We hold ourselves to the highest moral
                  standards.
                </li>
                <li>
                  <strong>Team Player:</strong> Superheroes don't work alone. We need individuals
                  who can collaborate effectively and support their fellow heroes.
                </li>
                <li>
                  <strong>Adaptability:</strong> The world is ever-changing, and so are the
                  challenges we face. Our heroes must be quick thinkers and adaptable problem
                  solvers.
                </li>
                <li>
                  <strong>Diversity:</strong> We believe in the power of diversity and inclusion. We
                  encourage heroes of all backgrounds and experiences to join us.
                </li>
              </ul>
            </div>
            <div>
              <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">
                <!-- 🌟  -->
                Why Join Us?
              </div>
              <ul>
                <li>Make a real difference in the world.</li>
                <li>Join a community of like-minded heroes.</li>
                <li>Receive top-notch training and support.</li>
                <li>Enjoy the thrill of facing incredible challenges.</li>
                <li>Become a symbol of hope and inspiration for others.</li>
              </ul>
            </div>
            <div>
              <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">
                <!-- 🌟  -->
                Meet Some of the Heroes
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <v-row justify="center" class="slider-row">
      <v-slide-group v-model="model" class="pa-4" center-active show-arrows>
        <v-slide-item
          v-for="(hero, index) in heroes"
          :key="hero.id"
          @click="handleClick(index)"
          :class="{ 'v-slide-item--active': index === activeIndex }"
        >
          <HeroCard
            :lameImagePath="hero.lameImagePath"
            :heroImagePath="hero.heroImagePath"
            :active="isActiveCard(index)"
            @onActivate="setToActive(index)"
          />
        </v-slide-item>
      </v-slide-group>
    </v-row>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import HeroCard from '../components/HeroCard.vue';
import AppFooter from '../components/Footer.vue';
import AppHeader from '../components/Header.vue';

export default {
  data() {
    return {
      model: null,
      activeIndex: 0,
      heroes: [
        {
          id: 0,
          lameImagePath: 'Kevin L',
          heroImagePath: 'Professor K.jpeg',
        },
        {
          id: 1,
          lameImagePath: 'Brandon A New',
          heroImagePath: 'Doctor Strongarm.jpg',
        },
        {
          id: 2,
          lameImagePath: 'Marcus G New',
          heroImagePath: 'Helix.jpeg',
        },
        {
          id: 3,
          lameImagePath: 'Brandon L New',
          heroImagePath: 'Captain Brando Rando.jpg',
        },
        {
          id: 4,
          lameImagePath: 'Al M',
          heroImagePath: 'Mr Incredulous.jpeg',
        },
        {
          id: 5,
          lameImagePath: 'Matt G',
          heroImagePath: 'Gigabrain.jpg',
        },
        {
          id: 6,
          lameImagePath: 'Jasmine B',
          heroImagePath: 'Nanoviolet.jpeg',
        },
        {
          id: 7,
          lameImagePath: 'Ben B',
          heroImagePath: 'Ben The Plumber.jpeg',
        },
        {
          id: 8,
          lameImagePath: 'Phillip H',
          heroImagePath: 'Fire Marshal Phil.jpg',
        },
        {
          id: 9,
          lameImagePath: 'Brad B',
          heroImagePath: 'B-Radical.jpeg',
        },
      ],
    };
  },
  components: { HeroCard, AppFooter, AppHeader },
  methods: {
    handleClick(index) {
      if (index !== this.activeIndex) {
        this.activeIndex = index;
      }
    },
    isActiveCard(index) {
      return index === this.activeIndex;
    },
    setToActive(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style scoped>
body {
  font-family: sans-serif;
}

#about-us > .row:not(.slider-row) {
  padding: 24px 18px !important;
  margin-left: 0;
  margin-right: 0;
}

#about-us > div.row.text-section > ul > li {
  margin-bottom: 20px;
}

.slider-row {
  margin-left: 0;
  margin-right: 0;
}

.slide-group-container {
  text-align: center;
}

.slide-group {
  max-width: 800px;
}

.success {
  scale: 0.5;
}

.scene {
  width: 200px;
  height: 260px;
  /* border: 1px solid #ccc; */
  margin: 40px 0;
  perspective: 600px;
}

.card {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 260px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  backface-visibility: hidden;
}

.card__face--front {
  /* background: red; */
}

.card__face--back {
  /* background: blue; */
  transform: rotateY(180deg);
}

/* this style is applied when the card is clicked */
.flipme {
  transform: rotateY(180deg);
}

ul li {
  margin-top: 30px;
}
</style>
