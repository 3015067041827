<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <div class="scene scene--card" style="height: 355px; width: 250px">
            <div
            class="card"
            @click="handleClick"
            @keydown.enter.prevent="
                cardOne == 'start' ? (cardOne = 'flipped') : (cardOne = 'start')
              "
              v-bind:class="{ flipme: cardOne == 'flipped' }"
              >
              <div class="card__face card__face--front">
                <v-fade-transition>
                  <div v-if="!active" class="not-active"></div>
                </v-fade-transition>
                <img
                :src="require(`@/assets/lames/${lameImagePath}.jpeg`)"
                alt="Avatar"
                style="width: 250px; height: auto"
                />
              </div>
              <div class="card__face card__face--back">
                <div  v-if="!active" class="not-active"></div>
                <img
                  :src="require(`@/assets/heroes/${heroImagePath}`)"
                  alt="Avatar"
                  style="width: 250px; height: auto"
                />
              </div>
            </div>
          </div>
        </v-col>
        <!-- <v-col>
          <div v-if="cardOne === 'start'" style="font-size: 1rem" class="mt-8">
            As a software developer, Brandon has extensive experience in developing and implementing
            software solutions for various industries. He possesses a strong knowledge of
            programming languages such as Java, Python, and C++, and has worked with various
            frameworks and technologies, including Spring, Django, and React. In his previous roles,
            Brandon has led development teams, managed project timelines, and collaborated with
            stakeholders to ensure the successful delivery of software solutions. He has experience
            with agile methodologies and has successfully implemented them to ensure efficient and
            timely project delivery. Additionally, Brandon has experience with database design and
            optimization, as well as working with cloud platforms such as AWS and Azure. He is
            comfortable working with both front-end and back-end technologies, and has experience
            with creating and integrating APIs. Overall, Brandon's experience as a software
            developer has equipped him with the skills and knowledge necessary to design, develop,
            and implement software solutions that meet the needs of clients and end-users. He is
            passionate about staying up-to-date with the latest technologies and continuously
            improving his skills to deliver high-quality software solutions.
          </div>
          <div v-if="cardOne === 'flipped'" style="font-size: 1rem" class="mt-8">
            Meet Brandon's alter-ego, Dr. Strongarm - a software developer by day and a
            crime-fighting superhero by night. Equipped with his brilliant mind and his arsenal of
            invention suits, Dr. Strongarm is the hero the city deserves. Using his extensive
            knowledge of programming languages and technologies, Dr. Strongarm has created a series
            of high-tech suits that allow him to fight crime with ease. From his Ironman-inspired
            suit that enables him to fly to his Spiderman-like suit that lets him crawl up walls,
            Dr. Strongarm has a suit for every occasion. But it's not just the suits that make Dr.
            Strongarm a force to be reckoned with. He also has a keen intellect and an unrelenting
            drive to protect his city from harm. Whether he's using his hacking skills to take down
            cybercriminals or his programming expertise to develop cutting-edge security software,
            Dr. Strongarm is always one step ahead of the bad guys. So the next time you're in
            trouble, just remember - Dr. Strongarm is on the case. With his quick thinking, his
            advanced technology, and his unwavering commitment to justice, he's the hero the city
            needs, and the hero it deserves. So, beware criminals! Dr. Strongarm is watching!
          </div>
        </v-col> -->
      </v-row>
    </v-container>
    <!-- <p>Click card to flip.</p> -->
  </div>
</template>

<script>
export default {
  name: 'HeroCard',
  data() {
    return {
      dialog: false,
      cardOne: 'start',
    };
  },
  props: {
    mainBelief: String,
    coreValue: String,
    quote: String,
    person: String,
    lameImagePath: String,
    heroImagePath: String,
    active: Boolean,
  },
  methods: {
    handleClick() {
      if (this.active) {
        if (this.cardOne === 'start') {
          this.cardOne = 'flipped';
        } else {
          this.cardOne = 'start';
        }
      } else {
        this.$emit('onActivate');
      }
    },
  },
};
</script>
<style>
body {
  font-family: sans-serif;
}

.scene {
  width: 200px;
  height: 260px;
  /* border: 1px solid #ccc; */
  margin: 40px 0;
  perspective: 600px;
}

.card {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
  /* inner border */
  /* border: 2px solid gray; */
  /* padding: 2px; */
  /* outer border */
  /* box-shadow: 0 0 0 2px gray;  */
}

.not-active {
  position: absolute; top: 0; width: 250px; height: 355px;
  background-color: #00000050;
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 260px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  backface-visibility: hidden;
}

.card__face--front {
  /* background: red; */
}

.card__face--back {
  /* background: blue; */
  transform: rotateY(180deg);
}

.card__face--front img, .card__face--back img{
  border: 2px solid gray;
  padding: 1px;
  border-radius: 5px;
}

/* this style is applied when the card is clicked */
.flipme {
  transform: rotateY(180deg);
}
</style>
